import BaseController from '../base_controller'

export default class CitationTableDimensionsController extends BaseController {
  static targets = [
    'cell',
    'xScale',
    'yScale',
    'insertRowIcon',
    'insertColIcon',
    'removeRowIcon',
    'removeColIcon'
  ]

  static values = {
    xScale: Number,
    yScale: Number
  }

  connect () {
    super.connect()
  }

  /* Show Gestures onHover Each Cell */
  mouseEnterCell (event) {
    const cell = event.target
    const rowId = Number(cell.dataset.rowId)
    const colId = Number(cell.dataset.colId)

    this.hideGestureIcons()

    if (rowId === 0 && colId === 0) {
      this.showRemoveRowIcon(cell)
      this.showRemoveColIcon(cell)
    } else if (colId === 0) {
      this.showInsertRowIcon(cell)
      this.showRemoveRowIcon(cell)
    } else if (rowId === 0) {
      this.showInsertColIcon(cell)
      this.showRemoveColIcon(cell)
    }
  }

  mouseLeaveCell (event) {
    const nextElement = event.relatedTarget
    const table = this.element.querySelector('table')

    if (table.contains(nextElement)) {
      return
    }

    if (this.insideGesture(nextElement)) {
      return
    }

    this.hideGestureIcons()
  }

  /* A check to see if an element is within the DOM tree of the add/remove gestures */
  insideGesture (element) {
    if (element === this.insertRowIconTarget ||
        element === this.insertColIconTarget ||
        element === this.removeRowIconTarget ||
        element === this.removeColIconTarget) {
      return true
    }

    const parent = element.parentElement
    if (parent === this.insertRowIconTarget ||
        parent === this.insertColIconTarget ||
        parent === this.removeRowIconTarget ||
        parent === this.removeColIconTarget) {
      return true
    }

    return false
  }

  showInsertRowIcon (cell) {
    const table = cell.offsetParent
    const alignmentOffset = -10

    const topOffset = cell.offsetTop + table.offsetTop + alignmentOffset
    const leftOffset = cell.offsetLeft + table.offsetLeft + alignmentOffset

    this.insertRowIconTarget.style.top = topOffset + 'px'
    this.insertRowIconTarget.style.left = leftOffset + 'px'
    this.insertRowIconTarget.hidden = false

    this.insertRowIconTarget.dataset.insertBeforeIndex = cell.dataset.rowId
  }

  showInsertColIcon (cell) {
    const table = cell.offsetParent
    const alignmentOffset = -10

    const topOffset = cell.offsetTop + table.offsetTop + alignmentOffset
    const leftOffset = cell.offsetLeft + table.offsetLeft + alignmentOffset

    this.insertColIconTarget.style.top = topOffset + 'px'
    this.insertColIconTarget.style.left = leftOffset + 'px'
    this.insertColIconTarget.hidden = false

    this.insertColIconTarget.dataset.insertBeforeIndex = cell.dataset.colId
  }

  showRemoveRowIcon (cell) {
    const table = cell.offsetParent
    const alignmentOffsetTop = 4
    const alignmentOffsetLeft = -14

    const topOffset = cell.offsetTop + table.offsetTop + alignmentOffsetTop
    const leftOffset = cell.offsetLeft + table.offsetLeft + alignmentOffsetLeft

    this.removeRowIconTarget.style.top = topOffset + 'px'
    this.removeRowIconTarget.style.left = leftOffset + 'px'
    this.removeRowIconTarget.hidden = false

    this.removeRowIconTarget.dataset.removeIndex = cell.dataset.rowId
  }

  showRemoveColIcon (cell) {
    const table = cell.offsetParent
    const alignmentOffsetTop = -16
    const alignmentOffsetLeft = 30

    const topOffset = cell.offsetTop + table.offsetTop + alignmentOffsetTop
    const leftOffset = cell.offsetLeft + table.offsetLeft + alignmentOffsetLeft

    this.removeColIconTarget.style.top = topOffset + 'px'
    this.removeColIconTarget.style.left = leftOffset + 'px'
    this.removeColIconTarget.hidden = false

    this.removeColIconTarget.dataset.removeIndex = cell.dataset.colId
  }

  hideGestureIcons () {
    this.insertRowIconTarget.hidden = true
    this.insertColIconTarget.hidden = true
    this.removeRowIconTarget.hidden = true
    this.removeColIconTarget.hidden = true
  }

  /* Row Manipulations */

  insertRow (event) {
    const rowId = event.target.closest('.insert-row-icon').dataset.insertBeforeIndex
    const row = this.element.querySelectorAll('tr')[rowId]

    const clonedRow = row.cloneNode(true)
    clonedRow.querySelectorAll('.citation-table-cell-trix-editor-hidden-input').forEach((input) => {
      input.value = ''
    })

    row.parentNode.insertBefore(clonedRow, row) // insert clonedRow before row

    this.refreshCellPositions()

    this.setTableYScale(Number(this.yScaleTarget.value) + 1)
  }

  removeRow (event) {
    event.stopPropagation()

    const totalCols = Number(this.yScaleTarget.value)
    if (totalCols === 1) {
      return
    }

    const rowId = event.target.closest('.remove-row-icon').dataset.removeIndex
    const row = this.element.querySelectorAll('tr')[rowId]
    row.remove()

    this.refreshCellPositions()

    this.setTableYScale(totalCols - 1)

    this.hideGestureIcons()
  }

  stopPropagation (event) {
  }

  modifyRowNumber (event) {
    event.preventDefault()
    const rows = Number(event.target.value)

    // if user input row number is invalid, restore original
    if (!rows || rows === 0 || rows === this.yScaleValue) {
      event.target.value = this.yScaleValue
      return
    }

    if (rows < this.yScaleValue) {
      this.removeLastRows(this.yScaleValue - rows)
    } else {
      this.appendLastRows(rows - this.yScaleValue)
    }

    this.setTableYScale(rows)
    return false
  }

  removeLastRows (amount) {
    const rows = this.element.querySelectorAll('tr')

    let lastIndex = rows.length - 1
    lastIndex = lastIndex - amount

    rows.forEach((row, idx) => {
      if (idx > lastIndex) {
        row.remove()
      }
    })

    this.refreshCellPositions()
  }

  appendLastRows (amount) {
    const row = this.element.querySelector('tbody').lastElementChild

    // remove trix hidden input values before cloning
    const savedTrixInputValues = []
    row.querySelectorAll('.citation-table-cell-trix-editor-hidden-input').forEach((input) => {
      savedTrixInputValues.push(input.value)
      input.value = ''
    })

    for (let i = 0; i < amount; i++) {
      const clonedRow = row.cloneNode(true)
      row.parentNode.appendChild(clonedRow)
    }

    // restore trix hidden inputs
    row.querySelectorAll('.citation-table-cell-trix-editor-hidden-input').forEach((input, idx) => {
      input.value = savedTrixInputValues[idx]
    })

    this.refreshCellPositions()
  }

  /* Col Manipulations */

  insertCol (event) {
    const colId = event.target.closest('.insert-col-icon').dataset.insertBeforeIndex

    const rows = this.element.querySelectorAll('tr')
    rows.forEach((row) => {
      const originalCell = row.querySelector(`td[data-col-id="${colId}"]`)

      const clonedCell = originalCell.cloneNode(true)
      clonedCell.querySelector('.citation-table-cell-trix-editor-hidden-input').value = ''

      originalCell.parentNode.insertBefore(clonedCell, originalCell)
    })

    this.refreshCellPositions()

    this.setTableXScale(Number(this.xScaleTarget.value) + 1)

    // need to show insert_col_icon at an updated location here,
    // because the column widths change when going from 1 column to 2 columns to 2+ columns
    this.showInsertColIcon(this.element.querySelector(`td[data-row-id="0"][data-col-id="${colId}"]`))
  }

  removeCol (event) {
    event.stopPropagation()

    const totalRows = Number(this.xScaleTarget.value)
    if (totalRows === 1) {
      return
    }

    const colId = event.target.closest('.remove-col-icon').dataset.removeIndex
    const cells = this.element.querySelectorAll('td')
    cells.forEach((cell) => {
      if (cell.dataset.colId === colId) {
        cell.remove()
      }
    })

    this.refreshCellPositions()

    this.setTableXScale(totalRows - 1)

    this.hideGestureIcons()
    return false
  }

  modifyColNumber (event) {
    event.preventDefault()
    const cols = Number(event.target.value)

    // if user input col number is invalid, restore original
    if (!cols || cols === 0 || cols === this.xScaleValue) {
      event.target.value = this.xScaleValue
      return
    }

    if (cols < this.xScaleValue) {
      this.removeLastCols(this.xScaleValue - cols)
    } else {
      this.appendLastCols(cols - this.xScaleValue)
    }

    this.setTableXScale(cols)
    return false
  }

  removeLastCols (amount) {
    const rows = this.element.querySelectorAll('tr')

    rows.forEach((row) => {
      const cells = row.querySelectorAll('td')

      let lastIndex = cells.length - 1
      lastIndex = lastIndex - amount

      cells.forEach((cell, idx) => {
        if (idx > lastIndex) {
          cell.remove()
        }
      })
    })

    this.refreshCellPositions()
  }

  appendLastCols (amount) {
    const rows = this.element.querySelectorAll('tr')

    rows.forEach((row) => {
      const cell = row.querySelector('td')

      const trixInput = cell.querySelector('.citation-table-cell-trix-editor-hidden-input')
      const savedTrixInputValue = trixInput.value
      trixInput.value = ''

      for (let i = 0; i < amount; i++) {
        const clonedCell = cell.cloneNode(true)
        cell.parentNode.appendChild(clonedCell)
      }

      trixInput.value = savedTrixInputValue
    })

    this.refreshCellPositions()
  }

  /* Reindex Cells */

  refreshCellPositions () {
    let cellIndex = 0
    const rows = this.element.querySelectorAll('tr')

    rows.forEach((row, rowIndex) => {
      const cells = row.querySelectorAll('td')

      cells.forEach((cell, colIndex) => {
        cell.dataset.rowId = rowIndex
        cell.dataset.colId = colIndex

        const trixEditor = cell.querySelector('.citation-table-cell-trix-editor')
        const original = trixEditor.getAttribute('input')
        const replacement = original.replace(/citation_table_cell_value_[\d*]/, `citation_table_cell_value_${cellIndex}`)
        trixEditor.setAttribute('input', replacement)

        const trixEditorHiddenInput = cell.querySelector('.citation-table-cell-trix-editor-hidden-input')
        trixEditorHiddenInput.id = replacement
        trixEditorHiddenInput.name = trixEditorHiddenInput.name.replace(/[\d*]/, cellIndex)

        const positionXInput = cell.querySelector('.citation-table-position-x')
        positionXInput.id = positionXInput.id.replace(/[\d*]/, cellIndex)
        positionXInput.name = positionXInput.name.replace(/[\d*]/, cellIndex)
        positionXInput.value = colIndex

        const positionYInput = cell.querySelector('.citation-table-position-y')
        positionYInput.id = positionYInput.id.replace(/[\d*]/, cellIndex)
        positionYInput.name = positionYInput.name.replace(/[\d*]/, cellIndex)
        positionYInput.value = rowIndex

        const confidenceScoreInput = cell.querySelector('.citation-table-confidence-score')
        confidenceScoreInput.id = confidenceScoreInput.id.replace(/[\d*]/, cellIndex)
        confidenceScoreInput.name = confidenceScoreInput.name.replace(/[\d*]/, cellIndex)

        const cellIdInput = cell.querySelector('.citation-table-cell-id')
        cellIdInput.id = cellIdInput.id.replace(/[\d*]/, cellIndex)
        cellIdInput.name = cellIdInput.name.replace(/[\d*]/, cellIndex)

        cellIndex++
      })
    })
  }

  /* Helper Functions */
  setTableXScale (x) {
    this.xScaleTarget.value = x
    this.xScaleValue = x

    const closestForm = this.element.closest('form') // new_citation or edit_citation form
    closestForm.querySelector('input#citation_citation_table_x_scale').value = x
  }

  setTableYScale (y) {
    this.yScaleTarget.value = y
    this.yScaleValue = y

    const closestForm = this.element.closest('form') // new_citation or edit_citation form
    closestForm.querySelector('input#citation_citation_table_y_scale').value = y
  }
}
